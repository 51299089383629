<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].display.label"
                        vid="display"
                        :label="beForm[rep].display.label"
                        v-model="form[rep].display"
                        placeholder="Inserisci un codice settore"
                        :rules="getRules('display')"
                        maxlength="3"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_hidden"
                        :name="beForm[rep].is_hidden.label"
                        label="Nascondi comparto"
                        v-model="form[rep].is_hidden"
                        :options="beForm[rep].is_hidden.options"
                        :rules="getRules('is_hidden')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="is_sundry"
                        :name="beForm[rep].is_sundry.label"
                        label="Mostra in oneri vari nel foglio quadratura"
                        v-model="form[rep].is_sundry"
                        :options="
                          beForm[rep].is_sundry.options || [
                            { value: 'N', text: 'No' },
                            { value: 'Y', text: 'Sì' },
                          ]
                        "
                        :rules="getRules('is_sundry')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card header="Estratti Conto" header-tag="header" class="mt-2">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        :name="beForm[rep].initial_balance_date.label"
                        vid="initial_balance_date"
                        :label="beForm[rep].initial_balance_date.label"
                        v-model="form[rep].initial_balance_date"
                        placeholder="Inserisci un codice settore"
                        :rules="getRules('initial_balance_date')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].initial_balance.label"
                        vid="initial_balance"
                        :label="beForm[rep].initial_balance.label"
                        v-model="form[rep].initial_balance"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('initial_balance')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <b-card
                header="Flusso contabilità fiscale"
                header-tag="header"
                class="mt-2"
                v-if="canVerb('fattutto', 'config')"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        name="fattutto_enabled"
                        groupLabel=""
                        :label="`Comparto attivo all'esportazione per contabilità fiscale`"
                        v-model="form.fattutto.enabled"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        name="fattutto_code"
                        vid="fattutto_code"
                        label="Codice Comparto per flusso esportazione contabilità fiscale"
                        v-model="form.fattutto.code"
                        placeholder="Inserisci un codice"
                        textcase="upper"
                        :rules="getCustomRules('fattutto_code')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="fattutto_partitario"
                        name="fattutto_partitario"
                        label="Partitario"
                        v-model="form.fattutto.partitario"
                        :options="[
                          { value: 0, text: 'Compagnia' },
                          { value: 1, text: 'Produttore' },
                          { value: 2, text: 'Codice' },
                          { value: 3, text: 'Nessuno' },
                        ]"
                        :rules="getCustomRules('fattutto_partitario')"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.fattutto.partitario === 2"
                    >
                      <base-input
                        name="fattutto_partitario_code"
                        vid="fattutto_partitario_code"
                        label="Codice Partitario per flusso esportazione contabilità fiscale"
                        v-model="form.fattutto.partitario_code"
                        placeholder="Inserisci un codice di massimo 5 caratteri"
                        maxlength="5"
                      /></div
                  ></b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-checkbox
                        name="negative_enabled"
                        groupLabel=""
                        :label="`Codice per flusso negativo`"
                        v-model="form.fattutto.negative_enabled"
                      />
                    </div>
                    <div
                      class="form-group col-md-3"
                      v-if="form.fattutto.negative_enabled"
                    >
                      <base-input
                        name="negative_code"
                        vid="negative_code"
                        label="Codice Comparto per flusso negativo"
                        v-model="form.fattutto.negative_code"
                        placeholder="Inserisci un codice di massimo 15 caratteri"
                        textcase="upper"
                        maxlength="15"
                        :rules="getCustomRules('negative_code')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { canVerb } from "@/utils/permissions";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "sector",
      id: this.$route.params.id,
      // #1023 serve a inglobare tutti i sector che hanno il nuovoCampo a Y, rimuovendo gli stessi dalle tabelle
      form: {
        fattutto: {
          code: "",
          enabled: false,
          partitario: null,
          partitario_code: null,
          negative_enabled: false,
          negative_code: "",
        },
        sector: {
          title: null,
          display: null,
          is_abstract: "N",
          is_hidden: null,
          is_system: "N",
          is_agency: "N",
          is_sundry: "N",
          initial_balance_date: "1-1-1970",
          initial_balance: 0.0,
        },
      },
    };
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    BaseCurrency,
  },
  methods: {
    canVerb,
    getCustomRules(field) {
      let rules = {};
      if (field === "fattutto_code") {
        if (this.form.fattutto.partitario === 2) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "fattutto_partitario") {
        if (this.form.fattutto.enabled) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      } else if (field === "negative_code") {
        if (this.form.fattutto.negative_enabled) {
          rules["required"] = true;
        } else {
          delete rules["required"];
        }
      }
      return rules;
    },
    endOfOnSubmit() {
      this.$store.dispatch("auth/sectors");
      this.$showSnackbar({
        preset: "success",
        text: `Azione Completata: Comparto di compagnia creato`,
      });
      this.shortcut(
        "module.PROF.COMP",
        null,
        "#CompanySectors",
        "filterCompanySectors"
      );
    },
    onSubmit() {
      this.isLoading = true;
      this.store(this.repository)
        .then((response) => {
          if (
            !this.form.fattutto.enabled &&
            !this.form.fattutto.code.length &&
            this.form.fattutto.partitario == null &&
            this.form.fattutto.partitario_code == null
          ) {
            this.endOfOnSubmit();
            return;
          }
          const sector_id = response?.data?.data?.id;
          const Repo = RepositoryFactory.get("fattutto");
          const payload = {
            configurable_id: sector_id,
            configurable_type: "sector",
            code: this.form.fattutto.code,
            ledger_type: this.form.fattutto.partitario,
            ledger_code: this.form.fattutto.partitario_code,
            // note: "",
            active: this.form.fattutto.enabled,
          };
          if (this.form.fattutto.negative_code) {
            payload.negative_code = this.form.fattutto.negative_code;
          }
          Repo.store_configuration(payload)
            .then(() => {
              this.endOfOnSubmit();
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
